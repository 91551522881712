* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: serif;
}

html, .app {
  width: 100%;
  height: 100%;
}

body {
  height: fit-content;
}

.header {
  z-index: 1000;
  background-color: #fff;
  border-bottom: 1px solid #0000004d;
  justify-content: space-between;
  align-items: center;
  height: 5vw;
  padding: 0 200px;
  display: -ms-flexbox;
  display: flex;
  position: sticky;
  top: 0;
  box-shadow: 0 0 10px #0000004d;
}

.logo {
  width: 80px;
  height: 80px;
  margin: 20px;
}

.nav-items > ul {
  align-items: center;
  display: -ms-flexbox;
  display: flex;
}

.nav-items > ul > li {
  text-align: center;
  margin: 20px;
  list-style: none;
}

.nav-items > ul > li > a {
  color: #000;
  text-decoration: none;
}

.cartnotification {
  color: #000;
  text-align: center;
  background-color: #0bdd2e;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 19px;
  font-weight: 500;
  position: absolute;
  top: 26px;
  right: 326px;
  transform: translateY(-50%);
}

.filter {
  justify-content: center;
  align-items: start;
  margin: 20px 30px;
  display: -ms-flexbox;
  display: flex;
}

.search {
  align-items: center;
  margin: 0 20px;
  display: -ms-flexbox;
  display: flex;
}

.searchbox {
  width: 30vw;
  height: 40px;
  margin-right: 5px;
}

.filter-btn {
  color: #fff;
  cursor: pointer;
  background-color: #ee7817;
  border: none;
  border-radius: 5%;
  height: 40px;
  padding: 0 10px;
  font-size: large;
}

.filter-btn:hover {
  background-color: #353333;
  scale: 1.01;
}

.res-cards {
  width: 100%;
  padding: 10px;
}

.res-cards > h3 {
  font-weight: lighter;
}

.res-cards > h4 {
  color: #494949;
  margin-top: 5px;
  font-weight: lighter;
}

.res-logo {
  height: 200px;
}

.res-logo > img {
  object-fit: cover;
  border-radius: 8%;
  width: 100%;
  height: 200px;
  transition: transform .1s;
}

.res-cards img:hover {
  cursor: pointer;
  transform: scale(.95);
}

.res-container {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 200px;
  display: -ms-flexbox;
  display: flex;
}

.res-container a {
  color: inherit;
  text-decoration: none;
}

.res-container > a {
  width: 25%;
  padding: 10px;
  transition: transform .1s;
}

.shimmer-container {
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 200px;
  display: -ms-flexbox;
  display: flex;
}

.shimmer-cards {
  width: 23%;
  height: 300px;
  margin: 10px;
}

.image-space {
  background-color: #e9f3fd;
  border-radius: 8%;
  width: auto;
  height: 150px;
  margin: 10px;
}

.heading-space {
  background-color: #e9f3fd;
  width: 80%;
  height: 10px;
  margin: 10px;
}

.info-space {
  background-color: #e9f3fd;
  width: 60%;
  height: 10px;
  margin: 10px;
}

.login {
  color: #fff;
  cursor: pointer;
  background-color: #16893f;
  border: none;
  border-radius: 5%;
  width: 80px;
  height: 40px;
  font-size: large;
}

.userinfo {
  color: #000;
  background-color: #fff;
  border-radius: 20px;
  padding: 5px 20px;
  display: -ms-flexbox;
  display: flex;
  position: fixed;
  bottom: 20px;
  right: 20px;
}

.userinfo span {
  color: #0bdd2e;
}

.res-rating {
  display: -ms-flexbox;
  display: flex;
}

.rating {
  color: #fff;
  background-color: #16893f;
  border-radius: 4px;
  padding: 0 4px;
}

.res-info {
  background-image: linear-gradient(#fff -6.71%, #ebebf2 56.19%, #dfdfe7 106.56%);
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  justify-content: space-between;
  align-items: center;
  width: 60%;
  height: fit-content;
  margin: 30px 20%;
  padding: 0 20px;
  display: -ms-flexbox;
  display: flex;
}

.res-info-detail {
  height: 100%;
}

.res-info-detail > h1 {
  color: #151515;
  margin: 40px 0;
  font-size: 28px;
}

.res-info-detail > h3 {
  font-weight: bold;
}

.res-info-detail > span {
  font-size: medium;
  font-weight: 600;
}

.res-info-detail > p {
  line-height: 30px;
}

.menu-logo > img {
  border-radius: 20px;
  width: 190px;
  height: 190px;
}

.res-menu {
  width: 60%;
  margin: 0 20%;
}

.res-menu h1 {
  color: #ee7817;
  margin: 10px 0;
}

.res-menu-items {
  border-bottom: 3px solid #282c3f1a;
  justify-content: space-between;
  width: 100%;
  height: 200px;
  padding: 20px;
  display: -ms-flexbox;
  display: flex;
}

.item-detail {
  width: 50%;
  height: 100%;
  line-height: 30px;
}

.item-image > img {
  object-fit: cover;
  border-radius: 20px;
  width: 150px;
  height: 150px;
}

.add-item {
  color: #16893f;
  cursor: pointer;
  z-index: 1;
  background-color: #fff;
  border: 2px solid #282c3f1a;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  font-size: large;
  font-weight: 600;
  transition: background-color .3s;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.remove-item {
  color: #fff;
  cursor: pointer;
  z-index: 1;
  background-color: #ee7817;
  border: 2px solid #282c3f1a;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  font-size: large;
  font-weight: 600;
  transition: background-color .3s;
  position: absolute;
  bottom: -10px;
  left: 50%;
  transform: translateX(-50%);
}

.clear-item {
  color: #fff;
  cursor: pointer;
  background-color: #16893f;
  border: 2px solid #282c3f1a;
  border-radius: 10px;
  width: 100px;
  height: 40px;
  margin: 10px 0;
  font-size: 15px;
  font-weight: 600;
}

.item-image {
  display: inline-block;
  position: relative;
}

.add-item:hover {
  transform: translate();
  color: #16893f;
  background-color: #d9dadb;
}

.about-body {
  background-color: #f1f8e1;
  width: 100%;
  height: 100vh;
}

.about-box {
  justify-content: center;
  align-items: center;
  height: 100vh;
  display: -ms-flexbox;
  display: flex;
}

.inner-box {
  justify-content: center;
  align-items: center;
  width: 340px;
  display: -ms-flexbox;
  display: flex;
  position: relative;
  overflow: hidden;
}

.aboutimage {
  width: 340px;
  height: 500px;
}

.card-hover {
  text-align: center;
  background-color: #86b970;
  border-top-left-radius: 150px 35px;
  border-top-right-radius: 150px 35px;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50%;
  padding: 20px;
  transition: bottom .3s ease-in-out, border-radius .3s ease-in-out;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  bottom: 0;
}

.card-hover span {
  color: #2d7f0a;
}

.card-hover a {
  padding: 15px;
}

.card-hover h2 {
  padding: 15px;
  font-size: x-large;
  font-weight: bolder;
  line-height: 30px;
}

.inner-box:hover .card-hover {
  background-color: #dee8c1;
  border-radius: 0 0 150px 150px / 0 0 35px 35px;
  bottom: 50%;
}

.github {
  color: #095e27;
  text-decoration: none;
}

.nav-items {
  font-weight: bolder;
}

.nav-items .nav-link {
  color: gray;
  text-decoration: none;
}

.nav-items .active-link {
  color: #bfaa0f;
}

.footer {
  color: #fff;
  background-color: #116466;
  -ms-flex-direction: column;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 10vh;
  display: -ms-flexbox;
  display: flex;
}

.footer span {
  color: #ff6309;
}

@media (width <= 768px) {
  body {
    height: fit-content;
  }

  .header {
    justify-content: space-evenly;
    width: 100%;
    height: 8vh;
    padding: 0;
    display: -ms-flexbox;
    display: flex;
  }

  .logo {
    width: 50px;
    height: 50px;
    margin: 10px;
  }

  .nav-items > ul {
    align-items: center;
    width: fit-content;
    display: -ms-flexbox;
    display: flex;
  }

  .nav-items > ul > li {
    text-align: center;
    margin: 1vw;
    list-style: none;
  }

  .nav-items > ul > li > a {
    color: #000;
    font-size: small;
    text-decoration: none;
  }

  .cart-icon {
    justify-content: center;
    align-items: center;
    width: auto;
    height: auto;
    display: -ms-flexbox;
    display: flex;
  }

  .cartnotification {
    position: relative;
    top: 0;
    right: 8px;
    transform: translateY(-50%);
  }

  .filter {
    justify-content: center;
    align-items: center;
    margin: 10px 0;
    display: -ms-flexbox;
    display: flex;
  }

  .search {
    align-items: center;
    margin: 0 10px 0 0;
    display: -ms-flexbox;
    display: flex;
  }

  .searchbox {
    height: 30px;
    margin: 0;
  }

  .filter-btn {
    height: 30px;
    font-size: smaller;
  }

  .filter-btn:hover {
    background-color: #353333;
    scale: 1.01;
  }

  .res-cards {
    width: 100%;
    padding: 10px;
  }

  .res-cards > h3 {
    font-size: medium;
    font-weight: bold;
    line-height: 30px;
  }

  .res-cards > h4 {
    color: #494949;
    margin-top: 5px;
    font-weight: lighter;
  }

  .res-logo {
    height: 200px;
  }

  .res-logo > img {
    object-fit: cover;
    border-radius: 8%;
    width: 100%;
    height: 200px;
    transition: transform .1s;
  }

  .res-cards img:hover {
    cursor: pointer;
    transform: scale(.95);
  }

  .res-container {
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    margin: 0 20px;
    display: -ms-flexbox;
    display: flex;
  }

  .res-container a {
    color: inherit;
    justify-content: center;
    text-decoration: none;
  }

  .res-container > a {
    width: 100%;
    padding: 10px;
    transition: transform .1s;
  }

  .shimmer-container {
    -ms-flex-flow: column wrap;
    flex-flow: column wrap;
    margin: 0 20px;
    display: -ms-flexbox;
    display: flex;
  }

  .shimmer-cards {
    width: 100%;
    height: 300px;
    margin: 10px;
  }

  .login {
    color: #fff;
    cursor: pointer;
    background-color: #16893f;
    border: none;
    border-radius: 5%;
    width: 80px;
    height: 30px;
    font-size: smaller;
  }

  .userinfo {
    color: #000;
    background-color: #fff;
    border-radius: 20px;
    padding: 5px 20px;
    display: -ms-flexbox;
    display: flex;
    position: fixed;
    bottom: 20px;
    right: 20px;
  }

  .userinfo span {
    color: #0bdd2e;
  }

  .res-rating {
    display: -ms-flexbox;
    display: flex;
  }

  .rating {
    color: #fff;
    background-color: #16893f;
    border-radius: 4px;
    padding: 0 4px;
  }

  .res-info {
    width: 90%;
    height: fit-content;
    margin: 30px 20px;
  }

  .res-info-detail h1 {
    font-size: x-large;
  }

  .res-menu {
    -ms-flex-direction: column;
    flex-direction: column;
    width: 90%;
    height: fit-content;
    margin: 0 20px;
    display: -ms-flexbox;
    display: flex;
  }

  .res-menu h1 {
    color: #ee7817;
    margin: 10px 0;
    font-size: x-large;
  }

  .res-menu-items {
    border-bottom: 3px solid #282c3f1a;
    justify-content: space-between;
    width: 100%;
    height: fit-content;
    padding: 20px;
    display: -ms-flexbox;
    display: flex;
  }

  .item-detail {
    width: 60%;
    height: 100%;
    line-height: 25px;
  }

  .item-detail > h3 {
    font-size: medium;
  }

  .item-image img {
    object-fit: cover;
    border-radius: 20px;
    width: 150px;
    height: 150px;
    display: block;
  }

  .add-item {
    color: #16893f;
    cursor: pointer;
    z-index: 1;
    background-color: #fff;
    border: 2px solid #282c3f1a;
    border-radius: 10px;
    width: 100px;
    height: 40px;
    font-size: large;
    font-weight: 600;
    transition: background-color .3s;
    position: relative;
    bottom: 20px;
    left: 50%;
    transform: translateX(-50%);
  }

  .remove-item {
    color: #fff;
    cursor: pointer;
    z-index: 1;
    background-color: #ee7817;
    border: 2px solid #282c3f1a;
    border-radius: 10px;
    width: 100px;
    height: 35px;
    font-size: small;
    transition: background-color .3s;
    position: absolute;
    bottom: -10px;
    left: 50%;
    transform: translateX(-50%);
  }

  .clear-item {
    color: #fff;
    cursor: pointer;
    background-color: #16893f;
    border: 2px solid #282c3f1a;
    border-radius: 10px;
    width: 100px;
    height: 35px;
    margin: 10px 0;
    font-size: small;
  }

  .item-image {
    display: inline-block;
    position: relative;
  }

  .add-item:hover {
    transform: translate();
    color: #16893f;
    background-color: #d9dadb;
  }
}
/*# sourceMappingURL=index.5af6fa55.css.map */
